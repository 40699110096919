export default function AboutDoc () {
    return (
    
        // <div className = "flex flex-col items-center justify-center md:flex-row md:px-0">
        // <div className=" w-full md:w-1/3 justify-center mb-4 mr-6">
        //     <img className="relative w-full h-auto" src="/images/doctor.jpeg" alt="Picture of the doctor" />
        // </div>
        // <div className="w-full md:w-2/3 items-center justify-center mb-4">
        // <h3 className="text-3xl font-bold text-[#1F6A99]">Our Doctor</h3>
        //     <h2 className="text-xl font-bold leading-tight text-black sm:text-3xl lg:text-2xl">
        //         DR. PRAVINA KOTESHWAR
        //     </h2>
        //     <h4 className = "text-xl">
        //         M.B.B.S, M.D
        //     </h4>
        //     <p className="text-gray-600">
        //         She is a health care professional with rich experience of clinical practice of 25 years and research
        //     experience of 19 years. She is the HOD of the Department of Emergency Medical Services at Sri Sri College of Ayurvedic Science
        //     & Research Hospital, Bangalore.
        //     <br/><br/>
        //     he is actively involved in organizing and conducting health care activities in various schools, colleges, societies & organizations both in urban and rural areas, she is also involved in various guest lecturers on medicine, clinical pharmacology, research methodology and education related topics at various academic and organizations. 
        //     </p>
        // </div>
        // </div>
      
        <div class="sm:flex items-center max-w-screen-xl">
    <div class="sm:w-1/3 p-10">
        <div class="image object-center text-center ">
            <img src="/images/doctor.jpeg" class="w-full"/>
        </div>
    </div>
    <div class="sm:w-2/3 p-5">
        <div class="text">
            <h3 className="text-3xl font-bold text-[#1F6A99] mb-2">Our Doctor</h3>
             <h2 className="text-xl font-bold leading-tight text-black sm:text-3xl lg:text-2xl">
                 DR. PRAVINA KOTESHWAR
             </h2>
             <h4 className = "text-xl">
                M.B.B.S, M.D
             </h4>
             <p className="text-gray-600">
                 She is a health care professional with rich experience of clinical practice of 25 years and research
             experience of 19 years. She is the HOD of the Department of Emergency Medical Services at Sri Sri College of Ayurvedic Science
             & Research Hospital, Bangalore.
             <br/><br/>
             She is actively involved in organizing and conducting health care activities in various schools, colleges, societies & organizations both in urban and rural areas, she is also involved in various guest lecturers on medicine, clinical pharmacology, research methodology and education related topics at various academic and organizations. 
             </p>
        </div>
    </div>
</div>
        
    )
}