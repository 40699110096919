export default function Contact (){
    return(
<section id = "contact" className="text-gray-600 bg-blue-100 body-font relative">
    <div className="container px-5 py-8 mx-auto flex sm:flex-nowrap flex-wrap">
    <div className="lg:w-1/3 md:w-1/2 flex flex-col md:ml-auto w-full md:py-4 md:mt-0 sm:mr-10">
        <h2 className="text-2xl text-[#1F6A99] font-bold uppercase">Contact</h2>
            <p className="my-4 leading-relaxed mb-5 text-gray-600"><b> GURUKRIPA CLINIC </b> <br/>
8th Main Rd, KSRTC Layout, 3rd Phase, J. P. Nagar, Bengaluru, Karnataka 560078
            </p>
            
            <p className="mt-2 leading-relaxed mb-5 text-gray-600"><b> Phone Number: </b> 
            <a href="tel:+918618891182">+91 86188 91182</a> , <a href="tel:08026582245">080 2658 2245</a> <br/>
            </p>
            <a href="mailto:clinicgurukripa@gmail.com" className="text-lg mt-2 leading-relaxed mb-5 text-gray-600"><b>Email: </b>
                clinicgurukripa@gmail.com
            </a>

            <p className="mt-2 leading-relaxed mb-5 text-gray-600"><b> Opening Hours </b> <br/>
            Monday - Saturday: <br/>
             8:00 am to 10:00 am  <br/>
             6:30 pm to  9:30 pm <br/>
            Sunday:   <br/> 
             8:00 am to 11:00 am <br/>
            </p>

            
        </div>
        <div
            className="lg:w-2/3 md:w-1/2 w-full rounded-lg overflow-hidden h-96 p-10 flex items-end  relative">
            <iframe className="absolute inset-0 w-full" frameBorder="0" title="map" marginHeight="0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.917434827165!2d77.59237597380921!3d12.913028016162333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae150c55555555%3A0x2e7a94140077437e!2sGURUKRIPA%20CLINIC!5e0!3m2!1sen!2sin!4v1710832086411!5m2!1sen!2sin" width="100%" height="640" allowFullScreen="" loading="lazy" ></iframe>
        </div>
    </div>
</section>
    )
}