import { React }from 'react';


const Header = ({setModal}) => {

    return (
    // <section className="bg-[#1F6A99] py-10">
    //         <div className=" container mx-auto px-8">
    //         <div className="flex flex-col md:flex-row items-center text-white justify-between">
    //             <div className="md:w-1/2 mb-6 md:mb-0">
    //                 <h2 className="italic text-white font-bold text-3xl text-slate-100  mb-6">Empowering Wellness, Embracing Holistic Care</h2>
    //                 <p className='mb-5'></p>
    //                 <div className='flex space-x-5'>
    //                 <button onClick={()=> setModal(true)} className='border-2 p-2 border-white hover:text-[#1F6A99] hover:bg-white'>BOOK AN APPOINTMENT</button> 
    //                 </div>
    //             </div>
    //             <div className="md:w-1/3 size-96 size-82">
    //                 <img src="/images/clinicPhoto.jpeg" alt="Clinic Photo"
    //                     className="w-full rounded-lg shadow-lg" />
    //             </div>
    //         </div>
    //     </div>
    //     </section>

    <section class="py-10 px-15">
    <div class="container mx-auto px-8 items-center justify-center lg:flex">
                <div class=" lg:text-left lg:w-1/3 mr-8 mb-2 ">
                    <h4 class="text-xl font-semibold text-[#1F6A99] ">GET PRIMARY CARE AND CONSULTATION</h4>
                    <h1 class="text-3xl font-semibold xl:text-5xl lg:text-5xl">Your Health is Our Priority</h1>
                    <p class="text-lg ">
                      <b>TIMINGS: </b><br/>
                        Mon- Sat:  <br/>
                        8:00 am to 10:00 am <br/>
                        6:30 pm to  9:30 pm<br/>
                        Sun:<br/>
                        8:00 am to 11:00 am<br/>
                    </p>
                </div>
        <div class="md:w-1/2">
            <img src="/images/clinicPhoto.jpg" alt="Hero Image" class="w-full rounded-xl"/>
        </div>
    </div>
</section>

    );
};

export default Header;