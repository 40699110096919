export default function Footer (){
    return (
    <footer className="flex flex-col justify-center py-4">

    <div className="flex justify-center space-x-5">
        {/* <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <img src="https://img.icons8.com/fluent/30/000000/facebook-new.png" />
        </a>
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <img src="https://img.icons8.com/fluent/30/000000/linkedin-2.png" />
        </a>
        <a href="https://www.instagram.com/gurukripa_clinic/" target="_blank" rel="noopener noreferrer">
            <img src="https://img.icons8.com/fluent/30/000000/instagram-new.png" />
        </a> */}
    </div>
    <p className="text-center text-gray-700 font-medium">&copy; 2024 Gurukripa Clinic. All rights reservered.</p>
</footer>
    )
}