import {React} from "react";

export default function ServicesList ({service}){
    return(
//         <div class="relative overflow-hidden rounded-lg border bg-slate-50 select-none hover:shadow hover:shadow-teal-200 p-3 sm:mx-2">
//     <div class="flex md:flex-col  h-[180px] justify-between rounded-md">
//         <img src={service.path} alt='' class="h-24 w-24 sm:h-auto sm:w-auto"/> 
//         <div class="mt-4 sm:mt-0 sm:ml-4 flex-grow justify-between space-y-2">
//             <h3 class="font-bold">{service.name}</h3>
//             <p class="text-sm text-muted-foreground">{service.description}</p>
//         </div>
//     </div>
// </div>

<div class="relative overflow-hidden rounded-lg border bg-white select-none hover:shadow hover:shadow-teal-200 p-3">
    <div class="flex md:h-[180px] h-[100px] md:flex-col justify-between rounded-md">
        <img src={service.path} alt='' class="h-24 w-24"/> 
        <div class="ml-4 flex-grow space-y-2 text-left ">
            <h3 class="font-bold">{service.name}</h3>
            <p class="text-sm text-muted-foreground">{service.description}</p>
        </div>
    </div>
</div>


    )
}