import React, { useState } from 'react';
import { PhoneIcon, Bars3BottomRightIcon, XMarkIcon } from '@heroicons/react/24/solid'

const Navbar = ({setModal}) => {
    let Links =[
        {name:"Dr. Pravina Koteshwar",link:"#about"},
        {name:"Services",link:"#services"},
        {name:"Contact",link:"#contact"},
      ];
      let [open, setOpen] =useState(false);

    return (
        <div class="top-0 left-0 w-full shadow-md sticky z-50">
    <div class="bg-[#1F6A99] py-2 text-white px-7 md:px-10 h-8 flex items-center justify-end">
        <p class="leading-relaxed flex items-center">
            <PhoneIcon class="mr-2 w-5 h-5" />
            <a href="tel:+918618891182">+91 86188 91182</a>, &nbsp;<a href="tel:08026582245">080 2658 2245</a>
        </p>
    </div>
    <div class="relative md:flex items-center justify-between bg-white py-4 md:px-10 px-7">
        {/* logo section */}
        <div class="font-bold text-2xl cursor-pointer flex items-center gap-1 text-[#1F6A99]">
            <span>GURUKRIPA CLINIC</span>
        </div>
        {/* Menu icon */}
        <div onClick={()=>setOpen(!open)} class="absolute right-8 top-6 cursor-pointer md:hidden w-7 h-7">
            {open ? <XMarkIcon/> : <Bars3BottomRightIcon />}
        </div>
        {/* linke items */}
        <ul className={` md:flex md:items-center md:pb-0 pb-4 absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-300 ease-in ${open ? 'top-14 bg-[#1F6A99] ' : 'top-[-500px] bg-white'}`}>
        {Links.map((link, index) => (
            <li key={index} className={` md:my-0 my-3 font-semibold ${index !== 0 ? 'md:ml-8' : ''}`}>
                <a href={link.link} className={`duration-500 hover:underline ${open ? 'text-white':'text-gray-800 hover:text-[#1F6A99]'}` }>{link.name}</a>
            </li>
        ))}
        </ul>
    </div>
</div>

    );
};

export default Navbar;