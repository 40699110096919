import ServicesList from "./ServicesList";


export default function Services (){
    let servicesAvailable = [
        { name: "General Health", description: "Comprehensive healthcare services covering a wide range of medical conditions and concerns.", path: "/images/services/generalhealthcheckup.svg" },
        { name: "First Aid for Injuries", description: "Immediate assistance and medical care provided for injuries, accidents, and emergencies.", path: "/images/services/firstaid.svg" },
        { name: "Diabetic Care", description: "Specialized care for individuals diagnosed with diabetes, including monitoring, medication, and lifestyle advice.", path: "/images/services/diabetescare.svg" },
        { name: "Women's Health", description: "Experience holistic women's health consultations,prioritizing your well-being.", path: "/images/services/womenhealth.svg" },
        { name: "Respiratory Care", description: " Providing tailored treatments to enhance breathing and respiratory function.", path: "/images/services/respiratorycare.svg" },
        { name: "Thyroid Disorders", description: "Offering specialized care for thyroid disorders ensuring hormonal balance and symptom relief.", path: "/images/services/thyroidcare.svg" },
        { name: "Pain Management", description: "Specialized care focused on relieving and managing acute and chronic pain.", path: "/images/services/painmanagement.svg" },
        { name: "Hypertension", description: "Diagnosis, monitoring, and management of high blood pressure, including lifestyle modifications.", path: "/images/services/bpissues.svg" }
    ];
    
    return(
<section id="services" class=" mx-0 justify-center bg-slate-50 py-8 md:py-12 lg:py-12">

<div class="mx-auto mb-4 flex max-w-[58rem] flex-col items-center space-y-4 text-center">
    <h3 class="font-bold text-3xl text-[#1F6A99]">Our Services</h3>
</div>

<div class="mx-auto grid justify-center gap-4 sm:grid-cols-2 md:max-w-[72rem] md:grid-cols-4 px-4 ">

    {servicesAvailable.map((service, index) => (
                <ServicesList key={index} service={service} />
            ))}
</div>
</section>
    )
}