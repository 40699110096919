import AboutDoc from "./AboutDoc";

export default function About () {
    return (
        <section id="about" class="flex flex-col justify-center items-center">
            <div class="md:mx-10 px-4 md:px-10 container ">
                <div class="p-4 my-6  items-center text-center bg-blue-100 ">
                    <h2 class="text-2xl text-[#1F6A99] font-bold uppercase">Our Clinic</h2>
                    <p class="text-lg md:w-2/3 mx-auto py-5">
                        Gurukripa Clinic located at J.P Nagar 3rd Phase is a general health clinic providing affordable, expert medical advice, and personalized care. Experience the exceptional care at our clinic, where compassion and commitment meet quality healthcare.
                    </p>
                </div>
                <AboutDoc />
            </div>
        </section>

    )

}