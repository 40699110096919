import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Header from './Header';
import Navbar from './Navbar';
import Services from './Services';
import About from './About';
import Contact from './Contact';
import Footer from './Footer';
import Information from './Information';

export default function App() {
  const initialValues = {
    name: "",
    email: "",
    date: "",
    phoneNumber: "",
    time: "",
  };

  const [modal,setModal] = useState(false);
  const [appInfo, setAppInfo] = useState(initialValues);


  const closeAppointment = () =>{
    setAppInfo(initialValues);
    setModal(false);
  }

  return (
    <BrowserRouter>
   
    <Navbar setModal = {setModal} />
       <Header setModal = {setModal} />
       {/* <Appointment open = {modal} onClose={closeAppointment} setAppInfo={setAppInfo} appInfo={appInfo}/> */}
       <About />
       <Services />
       <Contact />
       <Footer />
    </BrowserRouter>
  )
}

